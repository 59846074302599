<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Brand qo'shish</h3>
          </div>
        </div>

        <div class="card-body">
          <div class="form-group">
            <v-text-field
              :error-messages="brandErrors"
              outlined
              v-model="brand"
              label="Brand"
              dense
              @input="$v.brand.$touch()"
              @blur="$v.brand.$touch()"
            ></v-text-field>
          </div>
          <div class="d-flex justify-content-start border-top pt-10">
            <div class="mr-2">
              <button
                type="button"
                class="
                  btn btn-light-primary
                  font-weight-bold
                  text-uppercase
                  px-9
                  py-4
                "
                @click="save"
                :disabled="newBrendLoading"
              >
                <i v-if="newBrendLoading" class="el-icon-loading"></i> Saqlash
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
@import url(https://cdn.syncfusion.com/ej2/material.css);
</style>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { required } from 'vuelidate/lib/validators'
import Swal from 'sweetalert2'

export default {
  data() {
    return {
      brand: '',
      newBrendLoading: false
    }
  },
  validations: {
    brand: {
      required
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Brand qo'shish" }])
  },
  computed: {
    brandErrors() {
      const errors = []
      if (!this.$v.brand.$dirty) return errors

      !this.$v.brand.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    }
  },
  methods: {
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        Swal.fire({
          title: '',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      } else {
        this.submitStatus = 'PENDING'
        this.submitStatus = 'OK'
        const data = {
          name: this.brand
        }
        this.newBrendLoading = true
        this.$store
          .dispatch('createBrand', data)
          .then(() => {
            this.newBrendLoading = false
            this.brand = ''
            this.$v.$reset()
          })
          .catch((err) => {
            this.newBrendLoading = false
            console.error(err)
          })
      }
    }
  }
}
</script>

<style scoped>
.error {
  color: red;
  display: none;
}
.form-group--error {
  display: block;
}
</style>
